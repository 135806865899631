<template>
  <div class="onboarding-reatiler-section">
    <h2 class="section-header">Welcome to VGANG!</h2>
    <PCard :class="{ 'collapse-close': !open }" sectioned="">
      <div>
        <PStackItem class="step-guide-header">
          <h5 class="title">Discover VGANG</h5>
          <span
            v-if="!open"
            @click="open = !open"
            class="icon material-symbols-outlined"
          >
            expand_more
          </span>
          <span
            v-else
            @click="open = !open"
            class="icon material-symbols-outlined"
          >
            keyboard_arrow_up
          </span>

          <p class="desc">
            Navigate Our Personalized Guide to Get Your Store Sailing Smoothly
            with VGANG.
          </p>
          <div class="progress-bar-container">
            <span class="progress-title">{{ step }} of 5 tasks completed</span>
            <ProgressBar :step="step" />
          </div>
        </PStackItem>
        <PStackItem class="steps-section">
          <PCollapsible
            id="basic-collapsible"
            expandOnPrint
            :open="open"
            :transition="{ duration: '500ms', timingFunction: 'ease-in-out' }"
          >
            <div class="steps d-flex flex-column row-gap-5">
              <div :class="{ 'step-is-active': step > 1 }" class="step">
                <div class="header d-flex align-items-start">
                  <label class="checkbox-container">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                  <p class="title">Step 1: Find Your Perfect Product!</p>
                  <a
                    v-if="step === 1"
                    target="_blank"
                    class="link"
                    href="https://blog.vgang.io/category/vgang-for-dropshippers/"
                  >
                    Learn more
                  </a>
                </div>
                <div v-if="step === 1" class="body">
                  <p class="desc">
                    Explore, Filter by Tags, Categories, Locations and More –
                    Find the Products You Want to Sell and Add Them to Your List
                    Easily.
                  </p>
                  <div>
                    <PButton
                      @click="forwardOnboarding('ShopifyRetailerFindProducts')"
                      >Explore Products to Sell</PButton
                    >
                  </div>
                </div>
              </div>
              <div :class="{ 'step-is-active': step > 2 }" class="step">
                <div class="header d-flex align-items-start">
                  <label class="checkbox-container">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                  <p class="title">
                    Step 2: Edit & Import Products to Your Store.
                  </p>
                  <a
                    v-if="step === 2"
                    target="_blank"
                    class="link"
                    href="https://blog.vgang.io/category/vgang-for-dropshippers/"
                  >
                    Learn more
                  </a>
                </div>
                <div v-if="step === 2" class="body">
                  <p class="desc">
                    Tailor Product Details and Pricing to Fit Your Brand, Then
                    Seamlessly Push Your Selections to Your Storefront.
                  </p>

                  <PButton
                    @click="forwardOnboarding('ShopifyRetailerImportList')"
                  >
                    Edit & Import Now
                  </PButton>
                </div>
              </div>
              <div :class="{ 'step-is-active': step > 3 }" class="step">
                <div class="header d-flex align-items-start">
                  <label class="checkbox-container">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                  <p class="title">
                    Step 3: Fulfill Orders & Automate Tracking.
                  </p>
                  <a
                    v-if="step === 3"
                    target="_blank"
                    class="link"
                    href="https://blog.vgang.io/category/vgang-for-dropshippers/"
                  >
                    Learn more
                  </a>
                </div>
                <div v-if="step === 3" class="body">
                  <p class="desc">
                    Place Orders with Ease and Let Our System Handle the Rest,
                    Automating the Entire Fulfillment Process for a Smooth
                    Dropshipping Experience.
                  </p>

                  <PButton
                    @click="forwardOnboarding('ShopifyRetailerCheckout')"
                  >
                    Checkout & Automate Tracking
                  </PButton>
                </div>
              </div>
              <div :class="{ 'step-is-active': step > 4 }" class="step">
                <div class="header d-flex align-items-start">
                  <label class="checkbox-container">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                  <p class="title">Step 4: Join Our Community.</p>
                  <a
                    v-if="step === 4"
                    target="_blank"
                    class="link"
                    href="https://blog.vgang.io/category/vgang-for-dropshippers/"
                  >
                    Learn more
                  </a>
                </div>
                <div v-if="step === 4" class="body">
                  <p class="desc">
                    Connect with Fellow Dropshipper and Suppliers on LinkedIn to
                    Share Experiences, Gain Insights, and Grow Together.
                  </p>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/search/results/all/?keywords=dropshipping%20for%20impact%20entrepreneurs&origin=TYPEAHEAD_HISTORY&searchId=3acddb88-06d2-47ad-9c15-94c181289e6a&sid=nQ%40"
                  >
                    <PButton @click="forwardOnboardingLinkedIn()">
                      Join LinkedIn Community
                    </PButton>
                  </a>
                </div>
              </div>
              <div
                :class="{
                  'step-is-active': step >= 5 || userSteps?.onboarding_step5,
                }"
                class="step"
              >
                <div class="header d-flex align-items-start">
                  <label class="checkbox-container">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                  <p class="title">Step 5: Upgrade Your Plan</p>
                  <a
                    v-if="step === 5"
                    target="_blank"
                    class="link"
                    href="https://blog.vgang.io/category/vgang-for-dropshippers/"
                  >
                    Learn more
                  </a>
                </div>
                <div v-if="step === 5" class="body">
                  <p class="desc">
                    Explore Our Options and Find the Perfect Plan to Match Your
                    Needs, Taking Your Dropshipping Business to the Next Level.
                  </p>

                  <PButton @click="forwardOnboarding('ShopifyRetailerSetting')"
                    >Choose a plan</PButton
                  >
                </div>
              </div>
            </div>
          </PCollapsible>
        </PStackItem>
      </div>
    </PCard>
    <div class="Schedule-Thoughts-section mt-5">
      <div
        class="Polaris-LegacyCard green-Polaris-LegacyCard"
        style="border-radius: 8px"
      >
        <div class="Polaris-CalloutCard__Container">
          <div class="Polaris-LegacyCard__Section">
            <div class="Polaris-CalloutCard">
              <div class="Polaris-CalloutCard__Content p-5">
                <div class="Polaris-CalloutCard__Title">
                  <h2 class="Polaris-Text--root title Polaris-Text--headingMd">
                    Share Your Thoughts, Enjoy Free Access
                  </h2>
                </div>
                <div class="Polaris-TextContainer">
                  <p class="desc">
                    Leave Us a Review on Shopify and Get Access to Our Startup
                    Plan. We Value Your Feedback and Want to Hear Your
                    Experience!
                  </p>
                </div>
                <a
                  target="_blank"
                  href="https://calendly.com/vgang/vgang-for-dropshipers?month=2023-08"
                >
                  <PButton primary>Book Your Call Now</PButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="Polaris-LegacyCard"
        style="
          box-shadow: 0px 1px 2px 0px #00000026;

          box-shadow: 0px 0px 5px 0px #0000000d;
          border-radius: 8px;
        "
      >
        <div class="Polaris-CalloutCard__Container">
          <div class="Polaris-LegacyCard__Section">
            <div class="Polaris-CalloutCard">
              <div class="Polaris-CalloutCard__Content p-5">
                <div class="Polaris-CalloutCard__Title">
                  <h2 class="title Polaris-Text--root Polaris-Text--headingMd">
                    Share Your Thoughts, Enjoy Free Access
                  </h2>
                </div>
                <div class="Polaris-TextContainer">
                  <p class="desc">
                    Leave Us a Review on Shopify and Get
                    <span class="greenText">Three Month of Free</span>
                    Access to
                    <span class="greenText"> Our Startup Plan</span>. We Value
                    Your Feedback and Want to Hear Your Experience!
                  </p>
                </div>
                <a
                  target="_blank"
                  href="https://apps.shopify.com/vgang-dropshipping-retailers?search_id=18c2bbe9-1773-4798-8b19-fe205f7c7863&surface_detail=vgang&surface_inter_position=1&surface_intra_position=1&surface_type=search#adp-reviews"
                >
                  <PButton>Leave a Review & Get Started</PButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PCard
      class="knowledge-centre mt-5"
      :class="{ 'collapse-close': !openKnowledgeCentre }"
      sectioned=""
    >
      <div>
        <PStackItem class="step-guide-header">
          <h5 class="title">Knowledge centre</h5>
          <span
            v-if="!openKnowledgeCentre"
            @click="openKnowledgeCentre = !openKnowledgeCentre"
            class="icon-down icon material-symbols-outlined d-flex align-items-center justify-content-center"
          >
            expand_more
          </span>
          <span
            v-else
            @click="openKnowledgeCentre = !openKnowledgeCentre"
            class="icon-down icon material-symbols-outlined d-flex align-items-center justify-content-center"
          >
            keyboard_arrow_up
          </span>
        </PStackItem>
        <PStackItem class="steps-section">
          <PCollapsible
            id="basic-collapsible"
            expandOnPrint
            :open="openKnowledgeCentre"
            :transition="{ duration: '500ms', timingFunction: 'ease-in-out' }"
          >
            <div class="cards">
              <div class="card d-flex flex-row gap-4">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/vgang-dropshipping/"
                  class="icon-container"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15.8333 2.5C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H15.8333ZM15.4167 15.4167V11C15.4167 10.2795 15.1304 9.5885 14.621 9.07903C14.1115 8.56955 13.4205 8.28333 12.7 8.28333C11.9917 8.28333 11.1667 8.71667 10.7667 9.36667V8.44167H8.44167V15.4167H10.7667V11.3083C10.7667 10.6667 11.2833 10.1417 11.925 10.1417C12.2344 10.1417 12.5312 10.2646 12.75 10.4834C12.9688 10.7022 13.0917 10.9989 13.0917 11.3083V15.4167H15.4167ZM5.73333 7.13333C6.10464 7.13333 6.46073 6.98583 6.72328 6.72328C6.98583 6.46073 7.13333 6.10464 7.13333 5.73333C7.13333 4.95833 6.50833 4.325 5.73333 4.325C5.35982 4.325 5.0016 4.47338 4.73749 4.73749C4.47338 5.0016 4.325 5.35982 4.325 5.73333C4.325 6.50833 4.95833 7.13333 5.73333 7.13333ZM6.89167 15.4167V8.44167H4.58333V15.4167H6.89167Z"
                      fill="#007F5F"
                    />
                  </svg>
                </a>
                <div class="body">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/vgang-dropshipping/"
                    class="title"
                    >Dropshippers community in LinkedIn</a
                  >
                  <div class="desc">
                    Join Our LinkedIn Community and Engage with Fellow Members.
                    Share Your Experience, Gain Insights, and Grow Together with
                    VGANG.
                  </div>
                </div>
              </div>
              <div class="card d-flex flex-row gap-4">
                <div class="icon-container">
                  <span
                    class="material-symbols-outlined icon"
                    style="color: #007f5f"
                  >
                    forum
                  </span>
                </div>
                <div class="body">
                  <a
                    target="_blank"
                    href="https://blog.vgang.io/category/vgang-for-dropshippers/"
                  >
                    <h5 class="title">Frequently Asked Questions</h5></a
                  >
                  <div class="desc">
                    Have Questions? Find Answers and Insights in Our
                    Comprehensive FAQ Section. From Getting Started to Advanced
                    Features, We've Got You Covered.
                  </div>
                </div>
              </div>
              <div class="card d-flex flex-row gap-4">
                <div class="icon-container">
                  <span
                    class="material-symbols-outlined icon"
                    style="color: #007f5f"
                  >
                    payment
                  </span>
                </div>
                <div class="body">
                  <h5 class="title">Payment & Refund policy</h5>
                  <div class="desc">
                    Understanding Your Options is Simple. Explore Our Clear and
                    Transparent Payment & Refund Policy to Know What Works Best
                    for You.
                  </div>
                </div>
              </div>
              <div class="card d-flex flex-row gap-4">
                <div class="icon-container">
                  <span
                    class="material-symbols-outlined icon"
                    style="color: #007f5f"
                  >
                    mail
                  </span>
                </div>
                <div class="body">
                  <a target="_blank" href="https://vgang.io/contact">
                    <h5 class="title">Contact us</h5>
                  </a>
                  <div class="desc">
                    Have Questions, Need Assistance, or Just Want to Chat? Reach
                    Out to Us. We're Here to Help You Every Step of the Way.
                  </div>
                </div>
              </div>
            </div>
          </PCollapsible>
        </PStackItem>
      </div>
    </PCard>
  </div>
</template>

<script>
import ProgressBar from "@/components/bases/ProgressBar.vue";
import AuthService from "@/services/AuthenticationService";
import {
  setVariable,
  getVariable,
  removeVariable,
} from "@/utils/conditionalStoreVariables";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { ProgressBar },
  data() {
    return {
      open: true,
      step: null,
      openKnowledgeCentre: true,
      host: null,
      userSteps: null,
    };
  },
  computed: {
    ...mapGetters({
      newAlert: "alert/newAlert",
      alertError: "alert/alertError",
      products: "retailer/importedProductList",
      totalProductCount: "retailer/importedProductListTotalCount",
      listOfSellers: "retailer/listOfSellers",
    }),
  },
  async mounted() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    this.host = url.searchParams.get("host");
    try {
      const response = await AuthService.getRetailerInitialState(
        getVariable("shopifyToken")
      );
      this.userSteps = response?.data;
      // this.userSteps = {
      //   onboarding_step1: true,
      //   onboarding_step2: false,
      //   onboarding_step3: false,
      //   onboarding_step4: false,
      //   onboarding_step5: false,
      //   retailerID: 3547,
      //   userConnected: true,
      // };
      if (this.userSteps) {
        delete this.userSteps.retailerID;
        delete this.userSteps.userConnected;
      }

      for (let key in this.userSteps) {
        if (!this.userSteps[key]) {
          this.setStep(key);
          return;
        }
      }
      if (!this.step && this.userSteps?.onboarding_step5 == 5) {
        this.step = 5;
      } else if (!this.step) {
        this.step = 1;
      }
    } catch (e) {
      console.log(e);
      this["alert/newAlert"]({
        type: "error",
        message: e.data.message,
      });
      setTimeout(async () => {
        this.showAlertError = false;
        this["alert/clear"]();
      }, 3000);
    }
  },
  methods: {
    ...mapActions(["alert/newAlert", "alert/clear"]),
    async forwardOnboardingLinkedIn() {
      await this.forwardOnboarding();
      this.step++;
    },
    setStep(step) {
      let numberPart = parseInt(step.replace("onboarding_step", ""), 10);

      this.step = numberPart;
    },
    async forwardOnboarding(route) {
      try {
        const response = await AuthService.forwardOnboarding(this.step);
        if (route) {
          this.$router.push({
            name: route, // Replace with your actual route name
            query: { host: this.host },
          });
        }
      } catch (e) {
        console.log(e);
        this["alert/newAlert"]({
          type: "error",
          message: e.data.message,
        });
        setTimeout(async () => {
          this.showAlertError = false;
          this["alert/clear"]();
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss">
.onboarding-reatiler-section {
  .section-header {
    color: #202223;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .Polaris-Card__Section {
    padding-left: 0;
    padding-right: 0;
    transition: all 0.3s ease-in;
  }

  .steps-section {
    padding: 13px;
    transition: all 0.3s ease-in;
  }
  .progress-bar-container {
    .progress-title {
      width: 200px;
      font-size: 14px !important;
      font-weight: 400;
      line-height: 20px;
    }
    grid-column: 1 /-1;
    display: flex;
    align-items: center;
  }

  .step-guide-header {
    border-bottom: 1px solid #d9d9d9;
    padding-left: 10px;
    padding-right: 10px;
    display: grid;
    grid-template-columns: 1fr auto;
    padding-bottom: 14px;
    .title {
      color: #202223;
      font-size: 16px !important;
      cursor: pointer;
      font-weight: 700;
      line-height: 24px;
    }
    .desc {
      color: #202223;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .icon {
      text-align: right;
      padding: 3px;
      border-radius: 7px;
      cursor: pointer;
      margin-right: 8px;
      margin-bottom: 8px;
      transition: all 0.1s ease-in-out;
      &:hover {
        background: rgb(219, 219, 219);
      }
    }
  }
  .steps {
    .step-is-active {
      .title {
        color: #008060 !important;
      }
    }
    .step {
      .header {
        border: unset !important;
        .title {
          color: #202223;
          font-size: 14px !important;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          margin-bottom: 0;
          margin-left: 4px;
          padding-bottom: 8px !important;
        }
        un-check-icon {
          color: #5c5f62 !important;
        }
        .link {
          color: #2c6ecb !important;
          font-size: 14px !important;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin-left: auto;
          @include md {
            font-size: 12px !important;
          }
        }
      }
      .body {
        margin-left: 40px;
        .desc {
          color: #202223;
          font-size: 14px !important;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          @include md {
            font-size: 12px !important;
          }
        }
      }
    }
  }
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px #8c9196 solid;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .step-is-active .checkbox-container .checkmark {
    background-color: #008060;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .step-is-active .checkbox-container .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
  }
  .Schedule-Thoughts-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 21px;
    @include md {
      grid-template-columns: 1fr;
      row-gap: 18px;
    }
    .title {
      color: #202223;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .desc {
      color: #202223;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .polaris-checkbox-input[type="checkbox"] {
      accent-color: red;
    }
    .Polaris-LegacyCard {
      margin-top: 0 !important;
    }
    .green-Polaris-LegacyCard {
      background: #f1f8f5;
    }
  }
  .greenText {
    color: #008060 !important;
  }
}
.collapse-close {
  .step-guide-header {
    border-bottom: unset !important;
  }
  .Polaris-Card__Section {
    padding-bottom: 0;
  }
  .steps-section {
    padding: 0 !important;
    margin: 0 !important;
  }
}
.knowledge-centre {
  .icon-down {
    padding: 3px !important;
    margin: 0 !important;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 34px;
    @include md {
      grid-template-columns: 1fr;
    }
  }
  .card {
    border: unset;
  }
  .body {
    .title {
      color: #008060 !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .desc {
      color: #6d7175;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .icon-container {
    background: #f1f8f5;
    width: 55px;
    height: 40px;
    @include flex-center;
    border-radius: 50%;
    @include lg {
      width: 100px;
    }
    @include md {
      width: 75px;
    }
    @include sm {
      width: 120px;
    }
  }
}
</style>
