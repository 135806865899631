<template>
  <div class="progress-container">
    <div class="progress-bar" :style="{ width: `${step * 20}` + '%' }"></div>
  </div>
</template>

<script>
export default {
  props: ["step"],
};
</script>

<style>
.progress-container {
  width: 100%;
  background-color: #ccc;
  height: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 21px;
}

.progress-bar {
  height: 100%;
  background-color: #007a5c; /* You can change the color as needed */
  transition: width 0.3s ease-in-out;
}
</style>
